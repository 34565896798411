import { useState, useEffect } from "react";
import { DotLoading, Toast } from "antd-mobile";
import cardpng from "@/asserts/image/card.png";
import topLogo from "@/asserts/image/toplogo.png";
import "./index.css";
import axios from "axios";
function TopForm() {
  const [formValue, setformValue] = useState({
    mechanism_id: "",
    age: "",
    name: "",
    phone: "",
  });
  const [showMask, setshowMask] = useState(false);
  const [showCard, setshowCard] = useState(false);
  const [CardInfo, setCardInfo] = useState({ card_number: "", url: "" });

  interface Res {
    [key: string]: string;
  }
  useEffect(() => {
    let qpaser = (query: string) => {
      let obj = {} as Res;
      let arr1 = query.split("&");
      arr1.forEach((item) => {
        let arr2 = item.split("=");
        obj[arr2[0]] = arr2[1];
      });
      return obj;
    };
    const query = qpaser(window.location.search.substring(1));
    setformValue({ ...formValue, ...query });
    console.log(query, 333);
  }, []);
  function handleSubmit() {
    setshowMask(true);
    axios
      .post(
        "https://adminapi.tuoyuyun.cn/technology/Technology/addCard",
        formValue
      )
      .then((res) => {
        setshowMask(false);
        if (res.data.status === 1) {
          setCardInfo(res.data.data);
          setshowCard(true);
        } else {
          Toast.show({
            content: res.data.msg,
            position: "top",
          });
        }
      });
    console.log("提交的内容: ", formValue);
  }

  if (showCard) {
    return (
      <div className="topCard">
        <div className="header">体验卡已激活成功</div>
        <div className="form_intro">请截屏保存体验凭证</div>
        <img className="cradma" src={CardInfo.url} alt="" />
        <img className="cardpng" src={cardpng} alt="card" />
        <div className="number">编号：{CardInfo.card_number}</div>
        <div className="intro">本活动最终解释权归伊都教育所有</div>
      </div>
    );
  }
  return (
    <TopFormC
      showMask={showMask}
      formValue={formValue}
      setformValue={setformValue}
      handleSubmit={handleSubmit}
    />
  );
}
type formType = {
  name: string;
  age: string;
  phone: string;
  mechanism_id: string;
};
type myProps = {
  showMask: boolean;
  formValue: formType;
  handleSubmit: () => void;
  setformValue: (value: formType) => void;
};
function TopFormC(props: myProps) {
  let mask;
  if (props.showMask) {
    mask = (
      <div className="mask">
        <div className="text">
          正在激活中
          <DotLoading />
        </div>
      </div>
    );
  }
  return (
    <div className="topFrom">
      {mask}
      <img
        src={topLogo}
        style={{ height: "20vw", marginLeft: "20px" }}
        alt=""
      />
      <div className="header">体验卡激活</div>
      <div className="form_intro">填写下方信息即可激活体验权限</div>
      <div className="input_top">
        <input
          type="text"
          placeholder="宝宝名字"
          value={props.formValue.name}
          onChange={(e) => {
            props.setformValue({
              ...props.formValue,
              name: e.target.value,
            });
          }}
        />
        <input
          type="text"
          placeholder="宝宝月龄"
          value={props.formValue.age}
          onChange={(e) => {
            props.setformValue({
              ...props.formValue,
              age: e.target.value,
            });
          }}
        />
        <input
          placeholder="联系电话"
          type="text"
          value={props.formValue.phone}
          onChange={(e) => {
            props.setformValue({
              ...props.formValue,
              phone: e.target.value,
            });
          }}
        />
      </div>

      <div className="button">
        <span onClick={props.handleSubmit}>立即激活</span>
      </div>
      <div className="bottom">
        <div>
          <span>注：</span>1.激活完成请长按图片并保存用于验证。
        </div>
        <div style={{ marginLeft: "-8px" }}>2.每个宝宝仅限使用1张体验券。</div>
        <div style={{ color: "#A4A4A4" }}>本活动最终解释权归伊都教育所有</div>
      </div>
    </div>
  );
}

export default TopForm;
