import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import one from "@/asserts/image/one.png";
import two from "@/asserts/image/two.png";
import three from "@/asserts/image/three.png";
import four from "@/asserts/image/four.png";
import Logo from './components/Logo';
import TopForm from './components/TopFrom';

ReactDOM.render(
  <React.StrictMode>
    <TopForm />
    <Logo />
    <img style={{width:'100%',marginTop:'30px'}} src={one} alt="" />
    <img style={{width:'100%'}} src={two} alt="" />
    <img style={{width:'100%'}} src={three} alt="" />
    <img style={{width:'100%'}} src={four} alt="" />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
