import "./index.css";
import logopng from "@/asserts/image/logo.png";
import schoolpng from "@/asserts/image/school.png";

function Logo() {
  return (
    <div className="Logo">
      <div className="topa">
        <div className="top"></div>
        <img className="img" src={logopng} alt="" />
        <img className="imgs" src={schoolpng} alt="" />
      </div>
      <div className="bottom">
        <span>婴幼儿专业照护服务连锁品牌</span>
      </div>
    </div>
  );
}

export default Logo;
